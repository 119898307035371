export const environment = {
  production: true,
  apiUrl: 'https://pimcore.gdsys.com/webapp/',
  contentPath: '/cms/gdsys',
  additionalContentPaths: [],
  pageKey: 'gdsys',
  contentArray: 'contentElements',
  translationsKey: 'corporate-website/',
  inlineLinkMarker: /MYMARKER/gi,
  features: {
    comparisonList: true,
  },
  endpoints: {
    pages: 'pages/',
    generalPageInfo: 'general-page-info',
    stories: 'stories/filtered/',
    branches: 'branches/filtered/',
    mediaLibrary: 'medialibrary/filtered/',
    events: 'events/filtered/',
    glossary: 'glossary/filtered/',
    faq: 'faq/filtered/',
    products: 'products/',
    eventICS: 'events/',
    nav: '/navigations',
    translations: '/translations',
    interview: 'interview-answers/',
    comparisonPdf: 'comparisonpdf/',
  },
};
